<template>
  <div class="c-paymentMethods">
    <Typography
      v-text="$t('widgets.payment.section.paymentMethod.title')"
      variant="subtitle2"
    />
    <Typography
      v-text="$t('widgets.payment.section.paymentMethod.subText')"
      variant="body2"
    />
    <div
      :class="['c-paymentMethods__methods',
               !isPaymentMethodValid && hasOrderBeenSent ? '-isInValid' : '']"
    >
      <BaseSelect
        :options="paymentOptions"
        v-model="selectedPaymentMethod"
        name="select-payment-method"
        :placeholder-text="$t('common.select.paymentMethodPlaceHolderText')"
      />
    </div>
    <div
      v-if="creditIsActive || credit2IsActive"
      class="c-paymentMethods__credits"
    >
      <template v-if="hasCredit">
        <Typography
          v-text="$t('widgets.payment.section.paymentMethod.credit', { amount: userCredit })"
          variant="body2"
        />
        <BaseButton
          :btn-label="$t(`common.ctaLabels.${showCreditCta ? 'delete' : 'useCredit'}`)"
          variant="outlined"
          @click="applyCredit('credit')"
        />
      </template>
      <template v-if="hasCredit2">
        <Typography
          v-text="$t('widgets.payment.section.paymentMethod.prepaidCredit',
                     { amount: userCredit2 })"
          variant="body2"
        />
        <BaseButton
          :btn-label="$t(`common.ctaLabels.${showCredit2Cta ? 'delete' : 'prepaidCredit'}`)"
          variant="outlined"
          @click="applyCredit('credit2')"
        />
      </template>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';

import BaseSelect from '@/components/common/BaseSelect.vue';

const store = useStore();
const selectedPaymentMethod = ref('');
const emit = defineEmits([ 'change' ]);
const hasOrderBeenSent = computed(() => store.state.order.hasOrderBeenSent);
const userCredit = computed(() => store.state.auth.user.credit);
const userCredit2 = computed(() => store.state.auth.user.credit2);
const cartId = computed(() => store.state.cart.cart_items.cart_id);
const isPaymentMethodValid = computed(() => store.getters['order/isPaymentMethodValid']);
const creditIsActive = computed(() => store.getters['reusable/getSetting']('credit_active'));
const credit2IsActive = computed(() => store.getters['reusable/getSetting']('credit2_active'));

const hasCredit = computed(() => Number(userCredit.value > 0));
const hasCredit2 = computed(() => Number(userCredit2.value > 0));
const showCreditCta = computed(() => !!store.state.cart.cart_items.credit);
const showCredit2Cta = computed(() => !!store.state.cart.cart_items.credit2);
const paymentOptions = computed(
  () => store.state.payment.paymentMethods.map(d => ({
    id: d.id,
    label: d.name,
    value: d.name,
    selected: Number(store.state.order.orderData.payment_method) === d.id,
  })),
);

const applyCredit = type => {
  const params = {
    [type]: store.state.cart.cart_items[type] === 0 ? 1 : 0,
  };

  store.dispatch('cart/applyCredit', { id: cartId.value, params });
  emit('change');
};

watch(() => selectedPaymentMethod.value,
  currState => {
    store.dispatch('order/setOrderData', { payment_method: currState });
  });

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-paymentMethods {
  padding-bottom: 2.5rem;

  @include mx.bp('tablet-wide') {
    padding-bottom: 0;
  }

  &__methods {
    margin-top: 10px;
    padding: 18px 10px;

    border: 2px solid var(--#{ v.$variable-prefix}bg-02);
    border-radius: 3px;
    @include mx.d-flex('flex-start', 'center', false);

    &.-isInValid {
      border: 2px solid v.$error-color;
    }
  }

  &__credits {
    display: grid;
    justify-content: space-between;
    align-content: start;

    margin-top: 20px;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
}
</style>
