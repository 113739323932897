<template>
  <div class="c-selectDateAndTime">
    <Typography
      v-text="$t('widgets.payment.section.dateAndTime.title')"
      variant="subtitle2"
    />
    <div
      :class="['c-selectDateAndTime__container',
               !isDeliveryTimeValid && hasOrderBeenSent ? '-isInValid' : '']"
    >
      <div class="c-selectDateAndTime__gridItem">
        <input
          class="c-selectDateAndTime__input"
          type="date"
          :value="deliveryDate"
          :min="minDate"
          :max="maxDate"
          @change="handleDateChange"
        >
      </div>
      <div
        class="c-selectDateAndTime__gridItem"
        v-if="restaurantTimeOptions?.length"
      >
        <BaseSelect
          :options="restaurantTimeOptions"
          :value="deliveryTime"
          name="select-time"
          :placeholder-text="$t('common.select.timePlaceHolderText')"
          @change="handleTimeChange"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  computed, ref, onMounted,
} from 'vue';
import { useStore } from 'vuex';

import BaseSelect from '@/components/common/BaseSelect.vue';

const store = useStore();
const emit = defineEmits([ 'change' ]);

const deliveryType = computed(() => store.state.order.orderData.delivery_type);
const deliveryDate = computed(() => store.state.order.orderData.delivery_date);
const deliveryTime = computed(() => store.state.order.orderData.delivery_time);

const minDate = ref(new Date().toISOString().slice(0, 10));
const maxDate = ref(new Date(new Date().setDate(new Date().getDate() + 30))
  .toISOString().slice(0, 10));
const restaurantTimeOptions = computed(
  () => store.state.restaurant.restaurantTimes.map(d => ({
    label: d,
    value: d,
    selected: store.state.order.orderData.delivery_time === d,
  })),
);
const selectedRestaurantId = computed(() => store.state.restaurant.selectedRestaurant.id);
const isDeliveryTimeValid = computed(() => store.getters['order/isDeliveryTimeValid']);
const hasOrderBeenSent = computed(() => store.state.order.hasOrderBeenSent);

const getRestaurantTimesList = () => {
  store.dispatch('restaurant/getRestaurantTimesList',
    {
      id: selectedRestaurantId.value,
      params: {
        date: deliveryDate.value,
        type: deliveryType.value,
      },
    });
};

onMounted(() => {
  getRestaurantTimesList();
  store.dispatch('order/setOrderData',
    {
      delivery_date: deliveryDate.value,
      delivery_type: deliveryType.value,
    });
});

const handleDateChange = async evt => {
  await store.dispatch('order/setOrderData', { delivery_date: evt.target.value, delivery_time: '' });
  await store.dispatch('restaurant/getRestaurantTimesList',
    {
      id: selectedRestaurantId.value,
      params: {
        date: deliveryDate.value,
        type: deliveryType.value,
      },
    });
  emit('change');
};

const handleTimeChange = async evt => {
  await store.dispatch('order/setOrderData', { delivery_time: evt.target.value });
  emit('change');
};

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-selectDateAndTime {
  padding: 20px 0;

  &__container {
    position: relative;

    display: grid;

    margin-top: 10px;
    padding: 20px;

    border: 2px solid var(--#{ v.$variable-prefix}bg-02);
    border-radius: 3px;
    grid-template-columns: 100%;
    grid-auto-rows: auto;
    column-gap: 20px;
    @include mx.d-flex('flex-start', 'center', false);

    @include mx.bp('tablet-wide') {
      grid-template-columns: 48% 48%;
    }

    &.-isInValid {
      border: 2px solid v.$error-color;
    }
  }

  &__input {
    @include mx.use-color('bodyColorLt');

    width: 100%;

    background-color: transparentize(v.$body-color-light, 0.85);
  }

  &__gridItem {
    &:first-child {
      padding-bottom: 1rem;

      @include mx.bp('tablet-wide') {
        padding-bottom: 0;
      }
    }
  }
}
</style>
