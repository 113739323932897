<template>
  <Typography
    v-text="$t('widgets.payment.section.coupon.title')"
    variant="subtitle2"
  />
  <div class="l-coupons">
    <div
      v-for="({ id, text, points }) in customerAvailableDiscounts"
      :key="id"
      class="c-coupon"
    >
      <div class="c-coupon__text">
        <small v-text="text.split(' ').shift()" />
        <strong v-text="text" />
        <small v-text="`${points} Pt`" />
      </div>
      <BaseButton
        variant="solid"
        color="primary"
        type="submit"
        :btn-label="$t('common.ctaLabels.claimCoupon')"
        @click="handleClick(id)"
        is-full-width
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import { apiServices } from '@/api/services';
import BaseButton from '@/components/common/BaseButton.vue';

const store = useStore();
const emit = defineEmits([ 'change' ]);
const subTotalNoDiscounted = computed(() => Number(store.state.cart.cart_items.subTotalNoDiscount));
const deliveryCost = store.getters['order/getDeliveryCost'];
const customerAvailableDiscounts = await apiServices.getCustomerAvailableDiscounts();

const handleClick = async id => {
  await store.dispatch('cart/addCoupon', {
    discountCode: id,
    isCode: false,
    cart_subtotal: (subTotalNoDiscounted.value + deliveryCost),
  });
  emit('change');
};

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.l-coupons {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 300px));
  grid-gap: 10px;

  @include mx.bp('tablet', max) {
    justify-content: center;
  }
}

.c-coupon {
  @include mx.use-color('bodyColorDt');

  padding: 5px;

  border-radius: v.$base-border-radius;
  background-color: #222222;

  &__text {
    padding: 10px 0 20px 0;

    * {
      display: block;

      text-align: center;
    }
  }
}
</style>
