<template>
  <div
    class="c-baseTextArea"
  >
    <label
      :for="$attrs.id"
      :class="['c-baseTextArea__label', {'-srOnly': srOnly}]"
    >{{ label }} {{ $attrs.required ? ' *': '' }}</label>
    <div class="c-baseTextArea__container">
      <textarea
        v-bind="$attrs"
        :value="modelValue"
        v-validate
        :class="['c-baseTextArea__input',
                 (errors[`${$attrs.name}`] || customMessage) && '-hasError']"
        @input="updateInput"
      />
      <FieldLoader
        v-if="showLoader"
        class="-rightContent"
      />
    </div>

    <!-- TODO: Add a nice transition -->
    <span class="c-baseTextArea__errorMsg">
      {{ errors[`${$attrs.name}`] || customMessage }}
    </span>
  </div>
</template>

<script>
import FieldLoader from '@/components/common/Loader/FieldLoader.vue';

export default {
  name: 'BaseTextArea',
  components: { FieldLoader },
  emits: [ 'update:modelValue' ],
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    showLoader: {
      type: Boolean,
    },
    srOnly: {
      type: Boolean,
    },
  },
  data() {
    return {
      errors: {},
      customMessage: '',
    };
  },
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value);
    },
    validateForm(evt) {
      this.errors = {
        ...this.errors,
        [evt.target.name]: evt.target.validationMessage,
      };
    },
    customValidationMsg(message) {
      this.customMessage = message || '';
    },
  },
};
</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-baseTextArea {
  position: relative;

  margin-bottom: 1.2rem;
  width: 100%;

  &__label {
    @include mx.font-size(12px);

    letter-spacing: 0;
    text-transform: uppercase;

    color: transparentize(v.$body-color-light, 0.5);

    &.-srOnly {
      @include mx.hide-el-the-accessible-way;
    }
  }

  &__container {
    @include mx.d-flex(flex-start, center);

    width: 100%;
    padding: 0;
  }

  &__input {
    @include mx.use-color('bodyColorLt');
    @include mx.font-size(14px, 16px);

    box-sizing: border-box;
    width: 100%;
    margin: 0.5rem 0;
    padding: 12px 15px;

    white-space: nowrap;
    letter-spacing: 0.28px;
    text-overflow: ellipsis;

    border: none;
    border-radius: 3px;
    background-color: transparentize(v.$body-color-light, 0.9);

    &:focus {
      border: none;
      outline: none;
    }

    &.-hasError {
      border: 1px solid var(--#{ v.$variable-prefix}error);
    }

    &.-borderRadius {
      border-radius: 3px 0 0 3px;
    }
  }

  .-rightContent {
    padding: 12px 10px;

    border-radius: 0 3px 3px 0;
    background-color: transparentize(v.$body-color-light, 0.9);
  }

  &__errorMsg {
    @include mx.font-size(14px);
    @include mx.use-color('error');

    letter-spacing: 0.28px;
  }
}
</style>
