<template>
  <Page>
    <div class="c-payment">
      <BannerImageAndTitle
        :background="[
          {
            url: $t('widgets.payment.bannerImg[0].url'),
            format: $t('widgets.payment.bannerImg[0].format'),
            altText: $t('widgets.payment.bannerImg[0].altText'),
          },
          {
            url: $t('widgets.payment.bannerImg[1].url'),
            format: $t('widgets.payment.bannerImg[1].format'),
          },
        ]"
        :banner-title="$t('widgets.payment.bannerTitle')"
        navigate-back
      />

      <SectionContainer
        class="c-payment__sectionContainer"
        size="md"
      >
        <header class="c-payment__selectedRestaurant">
          <h1>{{ selectedRestaurant.name }}</h1>
          <p>{{ selectedRestaurant.address }}, {{ selectedRestaurant.city }}</p>
        </header>
        <DeliveryOptionTabs>
          <Typography
            class="-primary"
            v-text="$t('widgets.checkout.homeDelivery.subText', { time: deliveryTime })"
            variant="body1"
            v-if="selectedTab !== 'catering'"
          />
          <div class="c-payment__gridContainer">
            <div class="c-payment__gridItem">
              <!--address section-->
              <template v-if="selectedTab === 'delivery'">
                <Typography
                  class="h-mt-0"
                  v-text="$t('widgets.payment.section.address.title')"
                  variant="subtitle2"
                />
                <UserDeliveryAddresses @change="updateCart" />
              </template>

              <!--recipient section-->
              <Recipient v-if="!isAuthenticated" />
              <div
                :class="[
                  'c-payment__authNote',
                  isCustomerAuthFormInvalid && hasOrderBeenSent ? '-isInValid' : '',
                ]"
                v-if="isAuthenticated"
              >
                <BaseTextArea
                  :label="$t(`widgets.payment.section.profile.fields.${
                    isCateringOrder ? 'note_catering':'note'}.label`)"
                  name="note"
                  id="note"
                  :placeholder="
                    $t(`widgets.payment.section.profile.fields.${
                      isCateringOrder ? 'note_catering':'note'}.placeholder`)
                  "
                  v-model="authNote"
                  :required="isCateringOrder"
                />
              </div>

              <!--select date and time section-->
              <SelectDateAndTime
                @change="updateCart"
                v-if="selectedTab !== 'catering'"
              />

              <!--payment method section-->
              <PaymentMethods @change="updateCart" />

              <!--enable invoice-->
              <Invoice
                v-if="isAuthenticated && isInvoiceEnabled"
                @change="updateCart"
              /> <!--guest user can not ask for invoice-->
            </div>
            <div class="c-payment__gridItem">
              <Cart is-payment />
            </div>
          </div>
        </DeliveryOptionTabs>
      </SectionContainer>

      <!--coupon and promo code section-->
      <BaseContainer
        class="c-checkout__baseContainer"
        bg-color="02"
      >
        <SectionContainer
          class="c-payment__sectionContainer"
          size="md"
        >
          <suspense v-if="isAuthenticated && !hasLoyalty">
            <template #default>
              <Coupons @change="updateCart" />
            </template>
            <template #fallback>
              Loading customer discounts
            </template>
          </suspense>
          <AddPromoCode @change="updateCart" />
        </SectionContainer>
      </BaseContainer>
      <ModalAddressForm />
    </div>
  </Page>
</template>

<script setup>
import {
  computed, onMounted, ref, watch,
} from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { apiServices } from '@/api/services';
import Cart from '@/components/Cart.vue';
import ModalAddressForm from '@/components/ModalAddressForm.vue';
import Page from '@/components/Page.vue';
import BannerImageAndTitle from '@/components/banner/BannerImageAndTitle.vue';
import BaseTextArea from '@/components/common/BaseTextArea.vue';
import DeliveryOptionTabs from '@/components/common/DeliveryOptionTabs.vue';
import BaseContainer from '@/components/grid/BaseContainer.vue';
import AddPromoCode from '@/components/payment/AddPromoCode.vue';
import Coupons from '@/components/payment/Coupons.vue';
import Invoice from '@/components/payment/Invoice.vue';
import PaymentMethods from '@/components/payment/PaymentMethods.vue';
import Recipient from '@/components/payment/Recipient.vue';
import SelectDateAndTime from '@/components/payment/SelectDateAndTime.vue';
import UserDeliveryAddresses from '@/components/payment/addresses/UserDeliveryAddresses.vue';
// TODO: Fix ASAP all import/no-cycle
// eslint-disable-next-line import/no-cycle
import Trans from '@/mixins/translation';

const store = useStore();
const router = useRouter();

const authNote = ref(store.state.order.orderData.order_notes || '');

const isCustomerAuthFormInvalid = computed(
  () => store.getters['order/isCustomerAuthFormInvalid'],
);
const hasOrderBeenSent = computed(() => store.state.order.hasOrderBeenSent);
const deliveryDate = computed(() => store.state.order.orderData.delivery_date);
const deliveryTime = computed(() => store.state.order.orderData.delivery_time);
const selectedTab = computed(() => store.state.order.orderData.delivery_type || 'takeaway');
const cartProducts = computed(() => store.getters['cart/getCartProducts']);
const hasLoyalty = computed(() => store.state.cart.cart_items.has_loyalty);
const isAuthenticated = computed(() => store.state.auth.isAuthenticated);
const isInvoiceEnabled = computed(() => store.getters['reusable/getSetting']('enable_invoice'));
const selectedRestaurant = computed(() => store.state.restaurant.selectedRestaurant);
const selectedAddressForDelivery = computed(
  () => store.getters['order/getSelectedAddressForDelivery'],
);
const isCateringOrder = computed(
  () => store.getters['order/isCateringOrder'],
);
const deliveryDateTime = computed(() => {
  if (!deliveryTime.value) {
    return null;
  }

  return `${deliveryDate.value} ${deliveryTime.value.slice(0, deliveryTime.value.indexOf('-'))}`;
});

if (!selectedRestaurant.value) {
  apiServices.getDefaultRestaurantsList().then(response => {
    const restaurant = response.find(r => r.id === store.state.cart.cart_items.shop_id);

    store.dispatch('restaurant/setSelectedRestaurant', restaurant);
  });
}

// watch auth user note
watch(
  () => authNote.value,
  () => store.dispatch('order/setOrderData', { order_notes: authNote.value }),
);

onMounted(() => {
  if (!cartProducts.value?.length) {
    router.push(Trans.i18nRoutePath('/'));
  }

  if (isAuthenticated.value) {
    store.dispatch('auth/getUserInfo');
  }

  store.dispatch('payment/getPaymentMethods', selectedTab.value);
});

const updateCart = () => {
  const customerAddress = isAuthenticated.value ? 'customer_address_id' : 'customer_address';

  const payload = {
    ...(deliveryTime.value && { delivery_datetime: deliveryDateTime.value }),
    ...(selectedTab.value === 'delivery' && { [customerAddress]: selectedAddressForDelivery.value }),
  };

  store.dispatch(`cart/${isAuthenticated.value
    ? 'updateCartItems' : 'updateTempCartItems'}`, payload);
};

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-payment {
  &__authNote {
    padding: 20px;

    border: 2px solid var(--#{v.$variable-prefix}bg-02);
    border-radius: 3px;

    &.-isInValid {
      border: 2px solid v.$error-color;
    }
  }

  &__sectionContainer {
    padding: 2rem 0;
  }

  &::v-deep(.l-tabs) {
    @include mx.bp('desktop') {
      width: 65%;
    }
  }

  &::v-deep(.l-container) {
    background-color: transparent;
  }

  &__gridContainer {
    display: grid;

    padding-top: 5rem;
    grid-template-columns: 100%;
    grid-auto-rows: auto;
    column-gap: 23px;

    @include mx.bp('desktop') {
      grid-template-columns: 65% 33%;
    }
  }

  &__gridItem {
    &::v-deep(.c-modifyUserAddresses__addresses) {
      width: auto;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  &__selectedRestaurant {
    margin-bottom: 50px;
  }
}
</style>
