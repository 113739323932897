<template>
  <div
    :class="['c-modifyUserAddresses',
             {'-isInvalid':
               (!isBillingAddressValid ||
                 store.state.order.orderData.customer_address_id
                 === store.state.order.orderData.customer_billing_address_id
               ) && hasOrderBeenSent}]"
  >
    <div
      v-for="(addr) in userAddresses"
      :key="addr.id"
      :class="['c-modifyUserAddresses__addresses h-p-10',
               {'-border': Number(selectedBillingAddress) === addr.id}]"
    >
      <BaseRadio
        :id="`billing-addr-${addr.id}`"
        name="billing-addr"
        :value="addr.id"
        :checked="addr.default_billing"
        v-model="selectedBillingAddress"
        @change="handleChange(addr.id)"
      >
        <span class="c-modifyUserAddresses__addressesText">
          <span
            v-if="addr.name"
            class="body1 -fontBold"
            v-text="addr.name"
          />
          <span
            class="body1"
            v-text="`${addr.address}, ${addr.city}`"
          />
        </span>
      </BaseRadio>

      <small
        class="c-modifyUserAddresses__addressesBadge"
        v-if="addr.default_billing || addr.default_delivery"
        v-text="$t(
          `widgets.profileAddress.type-${addr.default_billing}${addr.default_delivery}`)"
      />
      <div class="c-modifyUserAddresses__addressesBtn">
        <BaseButton
          class="c-addressCard__editCta"
          :btn-label="isMobile ? '' : $t('common.ctaLabels.change')"
          :icon="isMobile ? 'edit' : ''"
          @click="handleModal(addr)"
        />
      </div>
    </div>
    <div class="h-p-10">
      <BaseButton
        icon="plus"
        icon-width="16"
        :btn-label="$t('widgets.profileAddress.addAddress')"
        @click="handleModal(UserAddressModel)"
      />
    </div>
  </div>
</template>

<script setup>
import {
  computed, inject, onMounted, ref, watch,
} from 'vue';
import { useStore } from 'vuex';

import BaseButton from '@/components/common/BaseButton.vue';
import BaseRadio from '@/components/common/BaseRadio.vue';
import { UserAddressModel } from '@/interface/model';
import { AUTH } from '@/store/types';

const store = useStore();
const isMobile = inject('isMobile');
const userAddresses = computed(() => store.state.auth.user.addresses
  .filter(address => !address.selectedForDelivery));
const isBillingAddressValid = computed(() => store.getters['order/isBillingAddressValid']);
const hasOrderBeenSent = computed(() => store.state.order.hasOrderBeenSent);

const selectedBillingAddress = ref(null);
const handleChange = id => {
  store.dispatch('order/setOrderData', { customer_billing_address_id: id });
  store.commit(`auth/${AUTH.SET_SELECTED_ADDRESS_FOR_BILLING}`, id);
};

const defaultBillingAddress = computed(
  () => store.getters['auth/getDefaultBillingAddress'],
);

const selectedAddressForBilling = computed(
  () => store.getters['order/getSelectedAddressForBilling'],
);

watch(() => selectedAddressForBilling.value, payload => {
  store.dispatch('order/setOrderData', { customer_billing_address_id: payload });
});

watch(() => defaultBillingAddress.value?.id, payload => {
  selectedBillingAddress.value = payload;
});

const handleModal = addr => {
  store.dispatch('auth/setSelectedAddressForEdit', addr);
  store.dispatch('overlays/setModal', 'AddressModal');
};

onMounted(() => {
  if (defaultBillingAddress.value) {
    selectedBillingAddress.value = defaultBillingAddress.value.id;
    store.dispatch('order/setOrderData', { customer_billing_address_id: defaultBillingAddress.value.id });
  }
});

</script>

<style src='./style.scss' lang="scss" scoped />
