<template>
  <div class="c-addPromoCode">
    <Typography
      v-text="$t('widgets.payment.section.promo.title')"
      variant="subtitle2"
    />
    <form
      @submit.prevent="addPromoCode"
      @invalid.capture.prevent="validate"
      class="c-addPromoCode__container"
    >
      <BaseTextBox
        required="true"
        class="c-addPromoCode__input"
        :placeholder="$t('widgets.payment.section.promo.addPromoText')"
        name="promo-code"
        id="promo-code"
        :ref="el => { inputEl['promo-code'] = el }"
        v-model="promoCode"
        sr-only
      />
      <BaseButton
        type="submit"
        variant="solid"
        color="primary"
        :btn-label="$t('widgets.payment.section.promo.btnText')"
      />
    </form>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

import BaseButton from '@/components/common/BaseButton.vue';
import BaseTextBox from '@/components/common/BaseTextBox.vue';

const store = useStore();
const promoCode = ref('');
const emit = defineEmits([ 'change' ]);
const isAuthenticated = computed(() => store.state.auth.isAuthenticated);
const inputEl = ref([]);
const subTotalNoDiscounted = computed(() => Number(store.state.cart.cart_items.subTotalNoDiscount));
const deliveryCost = store.getters['order/getDeliveryCost'];

const validate = evt => {
  inputEl.value[evt.target.id].validateForm(evt);
};

const addPromoCode = () => {
  const params = {
    discountCode: promoCode.value,
    cart_subtotal: (subTotalNoDiscounted.value + deliveryCost),
    ...!isAuthenticated.value && { cart_id: Number(sessionStorage.getItem('temp_cart_id')) },
  };

  store.dispatch('cart/addCoupon', params)
    .then(() => {
      promoCode.value = '';
      emit('change');
    });
};

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-addPromoCode {
  padding: 2rem 0;

  &__container {
    @include mx.d-flex(center, baseline, true);

    width: auto;
    padding: 20px;

    border: 2px solid darken(v.$light-color, 12%);
    border-radius: 3px;

    @include mx.bp('tablet-wide') {
      flex-direction: row;

      width: 62%;
    }
  }

  &::v-deep(.c-addPromoCode__input) {
    margin-top: 0;
    margin-bottom: 8px;
    padding-right: 0;

    @include mx.bp('tablet-wide') {
      margin: 0;
      padding-right: 10px;
    }
  }

  &::v-deep(.c-baseButton) {
    width: 100%;
    @include mx.bp('tablet-wide') {
      width: auto;
    }
  }
}
</style>
