<template>
  <div class="c-invoice">
    <Checkbox
      :text="$t('widgets.payment.section.invoice.enableInvoiceLabel')"
      :checked="needsInvoice"
      @change="handleInvoiceChange($event.target.checked)"
    />
    <keep-alive>
      <Checkbox
        v-if="needsInvoice && isDeliveryOrder"
        :text="$t('widgets.payment.section.invoice.addressCheckboxLabel')"
        :checked="billingIsDifferent"
        @change="handleBillingChange($event.target.checked)"
      />
    </keep-alive>
    <UserBillingAddresses
      v-if="billingIsDifferent && needsInvoice ||
        (needsInvoice && isTakeawayOrder)"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import Checkbox from '@/components/common/Checkbox.vue';
import UserBillingAddresses from '@/components/payment/addresses/UserBillingAddresses.vue';
import { AUTH } from '@/store/types';

const store = useStore();
const needsInvoice = computed(() => store.state.order.needsInvoice);
const billingIsDifferent = computed(() => store.state.order.billingIsDifferent);
const customer_address_id = computed(() => store.state.order.orderData.customer_address_id);
const isDeliveryOrder = computed(() => store.getters['order/isDeliveryOrder']);
const isTakeawayOrder = computed(() => store.getters['order/isTakeawayOrder']);

const handleInvoiceChange = checked => {
  store.dispatch('order/setNeedsInvoice', checked);
  store.commit(`auth/${AUTH.SET_SELECTED_ADDRESS_FOR_BILLING}`, checked ? customer_address_id.value : '');
  store.dispatch('order/setOrderData', { customer_billing_address_id: checked ? customer_address_id.value : null });
};

const handleBillingChange = checked => {
  store.dispatch('order/setOrderData', { customer_billing_address_id: null });
  store.dispatch('order/setBillingIsDifferent', checked);
};

</script>

<style lang="scss" scoped>
.c-invoice {
  padding-top: 1.5rem;
}
</style>
