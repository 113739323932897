<template>
  <div
    :class="['c-modifyUserAddresses', {'-isInvalid':
      (!isDeliveryAddressValid || !shippingIsValidForDelivery) && hasOrderBeenSent}]"
  >
    <div
      v-for="(addr) in userAddresses"
      :key="addr.id"
      :class="['c-modifyUserAddresses__addresses h-p-10',
               {'-border': Number(selectedDeliveryAddress) === addr.id}]"
    >
      <BaseRadio
        :id="`delivery-addr-${addr.id}`"
        name="delivery-addr"
        :value="addr.id"
        :checked="addr.default_delivery"
        v-model="selectedDeliveryAddress"
        @change="handleChange(addr)"
      >
        <span class="c-modifyUserAddresses__addressesText">
          <span
            v-if="addr.name"
            class="body1 -fontBold"
            v-text="addr.name"
          />
          <span
            class="body1"
            v-text="`${addr.address}, ${addr.city}`"
          />
        </span>
      </BaseRadio>

      <small
        class="c-modifyUserAddresses__addressesBadge"
        v-if="addr.default_billing || addr.default_delivery"
        v-text="$t(
          `widgets.profileAddress.type-${addr.default_billing}${addr.default_delivery}`)"
      />
      <div class="c-modifyUserAddresses__addressesBtn">
        <BaseButton
          class="c-addressCard__editCta"
          :btn-label="isMobile ? '' : $t('common.ctaLabels.change')"
          :icon="isMobile ? 'edit' : ''"
          @click="handleModal(addr)"
        />
      </div>
    </div>
    <div class="h-p-10">
      <BaseButton
        icon="plus"
        icon-width="16"
        :btn-label="$t('widgets.profileAddress.addAddress')"
        @click="handleModal(UserAddressModel)"
      />
    </div>
  </div>
</template>

<script setup>
import {
  computed, inject, onMounted, ref, watch,
} from 'vue';
import { useStore } from 'vuex';

import { apiServices } from '@/api/services';
import BaseRadio from '@/components/common/BaseRadio.vue';
import { UserAddressModel } from '@/interface/model';
import { AUTH } from '@/store/types';
import { toast } from '@/toast-notification';

const store = useStore();
const isMobile = inject('isMobile');
const emit = defineEmits([ 'change' ]);

const isAuthenticated = computed(() => store.state.auth.isAuthenticated);
const userAddresses = computed(() => store.state.auth.user.addresses);
const selectedRestaurantId = computed(() => store.state.restaurant.selectedRestaurant.id);

const selectedDeliveryAddress = ref(null);
const shippingIsValidForDelivery = computed(() => store.state.order.shippingIsValidForDelivery);
const isDeliveryAddressValid = computed(() => store.getters['order/isDeliveryAddressValid']);
const hasOrderBeenSent = computed(() => store.state.order.hasOrderBeenSent);
const isTakeawayOrder = computed(() => store.getters['order/isTakeawayOrder']);

const handleChange = address => {
  const { id, city, coord } = address;

  store.commit(`auth/${AUTH.SET_SELECTED_ADDRESS_FOR_DELIVERY}`, id);

  const params = isAuthenticated.value
    ? {
      shop_id: selectedRestaurantId.value,
      customer_address_id: id,
    } : {
      shop_id: selectedRestaurantId.value,
      customer_address_id: null,
      city,
      coord,
    };

  apiServices.checkShippingAddressForDelivery(params)
    .then(res => {
      store.dispatch('order/setShippingIsValidForDelivery', res.valid);
      if (!res.valid && !isTakeawayOrder.value) {
        toast.warning(res.message);
      }

      emit('change');
    });
};

const defaultDeliveryAddress = computed(
  () => store.getters['auth/getDefaultDeliveryAddress'],
);

const selectedAddressForDelivery = computed(
  () => store.getters['order/getSelectedAddressForDelivery'],
);

watch(() => selectedAddressForDelivery.value, payload => {
  store.dispatch('order/setOrderData',
    { [isAuthenticated.value ? 'customer_address_id' : 'customer_address']: payload });
});

const handleModal = addr => {
  store.dispatch('auth/setSelectedAddressForEdit', addr);
  store.dispatch('overlays/setModal', 'AddressModal');
};

watch(() => defaultDeliveryAddress.value?.id, payload => {
  selectedDeliveryAddress.value = payload;
});

onMounted(() => {
  if (defaultDeliveryAddress.value) {
    selectedDeliveryAddress.value = defaultDeliveryAddress.value.id;
    store.dispatch('order/setOrderData', { customer_address_id: defaultDeliveryAddress.value.id });

    const params = {
      shop_id: selectedRestaurantId.value || '1',
      customer_address_id: defaultDeliveryAddress.value.id,
    };

    apiServices.checkShippingAddressForDelivery(params)
      .then(res => {
        store.dispatch('order/setShippingIsValidForDelivery', res.valid);
        if (!res.valid && !isTakeawayOrder.value) {
          toast.warning(res.message);
        }
      });
  }
});

</script>

<style src='./style.scss' lang="scss" scoped />
