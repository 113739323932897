<template>
  <Typography
    v-text="$t('widgets.payment.section.profile.title')"
    variant="subtitle2"
  />
  <div
    :class="[
      'c-recipient',
      isCustomerGuestFormInvalid && hasOrderBeenSent ? '-isInValid' : '',
    ]"
  >
    <form>
      <BaseTextBox
        v-for="(
          { label, id, name, type, required, placeholder }, index
        ) in fields"
        :key="label"
        :label="$t(label)"
        :id="id"
        :name="name"
        :type="type"
        v-model="inputParams[index]"
        :ref="
          (el) => {
            inputEl[index] = el;
          }
        "
        :required="required"
        :placeholder="$t(placeholder)"
      />
      <BaseTextArea
        :label="$t(`widgets.payment.section.profile.fields.${
          isCateringOrder ? 'note_catering':'note'}.label`)"
        name="note"
        id="note"
        :placeholder="
          $t(`widgets.payment.section.profile.fields.${
            isCateringOrder ? 'note_catering':'note'}.placeholder`)
        "
        v-model="inputParams[4]"
        :required="isCateringOrder"
      />
      <div
        v-for="[k, v] in Object.entries(consents)"
        :key="k"
        @click.prevent="handleClick(k, v)"
      >
        <Checkbox
          name="consents"
          :text="$t(`widgets.register.consentLabels.${k}`)"
          :value="k"
          :id="k"
          :checked="v"
        />
      </div>
    </form>
    <BaseModal
      size="large"
      :show-modal="showModal"
    >
      <template #body>
        <AcceptTermsAndPolicy
          @accept="handleAction"
          :data="termsAndPolicy"
          is-recipient
        />
      </template>
    </BaseModal>
  </div>
</template>

<script setup>
import {
  computed, onMounted, provide, ref, watch,
} from 'vue';
import { useStore } from 'vuex';

import { apiServices } from '@/api/services';
import BaseModal from '@/components/common/BaseModal.vue';
import BaseTextArea from '@/components/common/BaseTextArea.vue';
import BaseTextBox from '@/components/common/BaseTextBox.vue';
import Checkbox from '@/components/common/Checkbox.vue';
import AcceptTermsAndPolicy from '@/components/user/modal/AcceptTermsAndPolicy.vue';
import { OrderModel } from '@/interface/model';

const store = useStore();
const inputParams = ref([]);
const inputEl = ref([]);
const consents = ref({ privacy: false, tos: false });
const option = ref({});
const termsAndPolicy = ref([]);
const showModal = computed(
  () => store.state.overlays.openModal === 'TermsAndPolicyModal',
);
const isCustomerGuestFormInvalid = computed(
  () => store.getters['order/isCustomerGuestFormInvalid'],
);
const isCateringOrder = computed(
  () => store.getters['order/isCateringOrder'],
);
const hasOrderBeenSent = computed(() => store.state.order.hasOrderBeenSent);

provide('option', option);

const fields = computed(() => [
  {
    label: 'widgets.payment.section.profile.fields.firstName.label',
    name: 'firstName',
    id: 'firstName',
    placeholder: 'widgets.payment.section.profile.fields.firstName.placeholder',
    required: true,
  },
  {
    label: 'widgets.payment.section.profile.fields.lastName.label',
    name: 'lastName',
    id: 'lastName',
    placeholder: 'widgets.payment.section.profile.fields.lastName.placeholder',
    required: true,
  },
  {
    label: 'widgets.payment.section.profile.fields.telephone.label',
    name: 'phoneno',
    id: 'phoneno',
    placeholder: 'widgets.payment.section.profile.fields.telephone.placeholder',
    type: 'tel',
    required: true,
  },
  {
    label: 'widgets.payment.section.profile.fields.emailId.label',
    name: 'email',
    id: 'email',
    placeholder: 'widgets.payment.section.profile.fields.emailId.placeholder',
    type: 'email',
    required: true,
  },
]);

watch(
  () => [ consents.value, ...inputParams.value ],
  currState => {
    const [ accept, firstName, lastName, telephone, emailId, note ] = currState;

    store.dispatch('order/setOrderData', {
      customer: {
        email: emailId,
        name: firstName,
        surname: lastName,
        tos: accept.tos,
        privacy: accept.privacy,
        mobile: telephone,
      },
      order_notes: note,
    });
  },
);

const handleClick = (value, checked) => {
  option.value = value;
  if (checked) {
    consents.value = { ...consents.value, [value]: false };
  } else {
    store.dispatch('overlays/setModal', 'TermsAndPolicyModal');
  }
};

const handleAction = ({ value, accept }) => {
  consents.value = { ...consents.value, [value]: accept };
  store.dispatch('overlays/setModal', '');
};

onMounted(() => {
  apiServices.getTermsAndPolicy().then(res => {
    termsAndPolicy.value = res;
  });

  // Empty saved memory of input fields when mounted
  store.dispatch('order/setOrderData', {
    customer: OrderModel.customer,
    order_notes: OrderModel.order_notes,
  });
});
</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-recipient {
  padding: 20px;

  border: 2px solid var(--#{v.$variable-prefix}bg-02);
  border-radius: 3px;

  &.-isInValid {
    border: 2px solid v.$error-color;
  }
}
</style>
